.center {
  .slick-center {
    transform: scale(1.258);
  }

  .slick-prev::before {
    display: none;
  }
  .slick-next::before {
    display: none;
  }

  .slick-prev {
    background-color: white !important;
    left: -45px;
  }
  .slick-next {
    background-color: white !important;
    right: -45px;
  }

  @media only screen and (max-width: 1023px) {
    .slick-prev {
      background-color: white !important;
      left: 0px;
      z-index: 99999;
    }
    .slick-next {
      background-color: white !important;
      right: 0px;
    }
  }
}
