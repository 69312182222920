@import "./variables";

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .rbc-timeslot-group {
    flex: 1;
    border-bottom: none;
    color: $primaryDark600;
    
    .rbc-time-slot {
      font-size: 0.85rem;
    }
  }
}
.rbc-timeslot-group {
  border-bottom: 1px solid $cell-border;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}


.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
  
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
  
  .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: auto;
    top: 0;
    
    &.rbc-rtl {
      left: 10px;
      right: 0;
    }
  }
  
  .rbc-event {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    min-height: 45px;
    flex-flow: column wrap;
    align-items: flex-start;
    position: absolute;
    overflow: hidden;
    overflow-wrap: break-word;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    max-height: 100%;
    border-radius: 6px;
  }
  
  .rbc-background-event {
    @extend .rbc-event;
    opacity: 0.75;
  }
  
  .rbc-event-label {
    display: none;
  }
  
  .rbc-event-content {
    width: auto;
    min-width: 12rem;
    flex: 1 1 0;
    word-wrap: break-word;
    background: linear-gradient(240.98deg, #e6e8ff 0%, #ffeae1 68.46%, #fffbea 99.58%);
    line-height: 1;
    min-height: fit-content;
    white-space: break-spaces;
    z-index: 200;
    flex-flow: column wrap;
    overflow: hidden;
    box-shadow: 1px 10px 30px rgba(56, 69, 114, 0.1);
    border-radius: 6px;
    position: relative;
  }
  
  .rbc-timeslot-group {
    border-top: 1px solid $secondaryBlue100;
  }
  
  .rbc-time-slot {
    border-top: 1px solid $primaryDark200;
  }
}

.rbc-time-view-resources {
  .rbc-time-gutter,
  .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid $cell-border;
    z-index: 10;
    margin-right: -1px;
  }
  
  .rbc-time-header {
    overflow: hidden;
  }

  .rbc-time-header-content {
    min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px;
  }

  .rbc-time-header-cell-single-day {
    display: none;
  }

  .rbc-day-slot {
    min-width: 140px;
  }

  .rbc-header,
  .rbc-day-bg {
    width: 140px;
    // min-width: 0;
    flex: 1 1 0;
    flex-basis: 0 px;
  }
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;

  &.rbc-now {
    font-weight: bold;
  }
}

.rbc-day-header {
  text-align: center;
}
