/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --scrollbar-width: 5px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.adornment-spacer {
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.custom-form-icon {
  width: 1.2em;
  margin-left: 0.12em;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: 0.5px;
}

::-webkit-scrollbar-track {
  background: #eff1ed;
}

::-webkit-scrollbar-thumb {
  background: #c5c7c3;
  border-radius: 1em;
}

::-webkit-scrollbar-thumb:hover {
  background: #969794;
}

.owl-item:not(.active) {
  opacity: 0.5;
}

.owl-item.active {
  opacity: 1;
}

.owl-item.active ~ .owl-item.active {
  opacity: 0.5;
}

.not-highlighted .owl-item:not(.active) {
  opacity: 1;
}

.not-highlighted .owl-item.active {
  opacity: 1;
}

.not-highlighted .owl-item.active ~ .owl-item.active {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.not-highlighted .owl-item.center .css-eawgl3 {
  width: 95%;
  height: 350px;
  margin-top: 2rem;
  animation: fadeIn 1s;
}

.not-highlighted .owl-item:not(.center) .css-eawgl3 {
  margin-top: 4rem;
  width: 85%;
  /*transition: width 1s ease;*/
}

.editor-image-class {
  border-radius: 4px;
}
.ProseMirror p {
  font-size: 0.8rem;
}

.editor-bold-text-class {
  font-family: Gellix-Bold;
}

.grid-auto-align:after {
  content: '';
  flex: auto;
}

.stickyElement {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}

.textOverflowEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
