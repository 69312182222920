@use "sass:math";

$primaryLight100: #e3dbfc;
$primaryLight300: #aa96f8;
$primaryLight500: #6d51f6;
$primaryLight700: #3a29b0;
$primaryDark100: #f9f9f9;
$primaryDark200: #f6f6f6;
$primaryDark300: #bfc6d7;
$primaryDark400: #a5aab9;
$primaryDark500: #79829b;
$primaryDark600: #384572;
$primaryDark700: #383838;
$primaryDark900: #000000;
$secondaryPink100: #fff2f4;
$secondaryPink200: #ffacac;
$secondaryPink500: #ff6174;
$secondaryPink600: #ff4085;
$secondaryGreen100: #f5fed4;
$secondaryGreen200: #e8fcab;
$secondaryGreen500: #abeb4d;
$secondaryGreen700: #34a854;
$secondaryBlue100: #e6e8ff;
$secondaryBlue200: #aef4fd;
$secondaryBlue500: #8f9afe;
$secondaryBlue700: #4051ec;
$secondaryYellow100: #fef9d8;
$secondaryYellow200: #fdf3b2;
$secondaryYellow500: #f8d653;
$secondaryYellow700: #e3a900;

$primaryGradient: linear-gradient(
    240.98deg,
    #e6e8ff 0%,
    #ffeae1 68.46%,
    #fffbea 99.58%
  );

$out-of-range-color: #F9F9F9;
$out-of-range-bg-color: #F9F9F9 !important;

$calendar-border: #ddd !default;
$cell-border: $secondaryBlue100 ;

$border-color: $secondaryBlue500 !default;

$segment-width: percentage(math.div(1, 7)) !default;

$time-selection-color: $primaryDark600 !default;
$time-selection-bg-color: rgba(0, 0, 0, 0.5) !default;
$date-selection-bg-color: lighten(#987bff, 22%) !default;

$event-bg: #fff !default;
$event-border: darken(#987bff, 10%) !default;
$event-outline: #987bff !default;
$event-color: #987bff !default;
$event-border-radius: 5px !default;
$event-padding: 5px 8px !default;
$event-zindex: 4 !default;

$btn-color: #373a3c !default;
$btn-bg: #e8deff !default;
$btn-border: #444845 !default;

$current-time-color: #74ad31 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: #e5dcff !default;

$primary-font: Gellix-Regular !default;

