.ourPeopleCarousel {
  .alice-carousel__dots {
    bottom: 15px;

    @media (min-width: 1200px) {
      display: flex;
      bottom: 130px;
    }
  }

  .alice-carousel__stage-item  {
    opacity: 1 !important;

    @media (min-width: 0px) {
      padding-right: 0px !important;
    }

    @media (min-width: 600px) {
      padding-right: 15px !important;
    }
  }
}
