@import './variables';
@import './reset';

.rbc-calendar {
  box-sizing: border-box;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: $out-of-range-color;
}

.rbc-off-range-bg {
  background: $out-of-range-bg-color;
}

td, 
div {
  font-family: $primary-font;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 1rem 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  font-family: $primary-font;
  min-height: 0;
  :first-child{
    border-top-left-radius: 8px;
  }
  & > a {
    &,
    &:active,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-button-link {
  color: #fff;
  background: none;
  font-weight: 600;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: text;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 0;
  font-family: $primary-font;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;

  .rbc-row-content-scroll-container {
    height: 100%;
    overflow-y: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}


@import './toolbar';
@import './event';
@import './month';
@import './agenda';
@import './time-grid';
