.sponaMediaPricingCarousel {
  .alice-carousel__dots {
    position: relative;
    // left: 0;
    bottom: 15px;
    display: flex;
    align-items: center;

    @media (min-width: 0px) {
      width: 100%;
      justify-content: center;
      bottom: -10px;
    }

    @media (min-width: 600px) {
      width: auto;
      bottom: -10px;
    }
  }

  .alice-carousel__stage-item.__target {
    opacity: 1 !important;
  }

  .alice-carousel__stage-item {
    margin-top: 30px;
    // margin-bottom: 30px;
    opacity: 0.4 !important;

    @media (min-width: 0px) {
      padding-right: 0px !important;
    }

    @media (min-width: 600px) {
      padding-right: 0px !important;
    }
  }
}
