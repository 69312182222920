.related {
  height: 100%;
  .slick-prev::before {
    display: none;
  }
  .slick-next::before {
    display: none;
  }

  .slick-prev {
    background-color: white !important;
    left: -10px;
    z-index: 99999;
  }
  .slick-next {
    background-color: white !important;
    right: -10px;
  }
}
