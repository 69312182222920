.alice-carousel {
  position: static !important;
  margin-right: 0;
}

.alice-carousel__dots {
  position: absolute;
  left: 0;
  bottom: 15px;
  display: flex;
  align-items: center;

  @media (min-width: 0px) {
    width: 100%;
    justify-content: center;
    bottom: -50px;
  }

  @media (min-width: 600px) {
    width: auto;
    bottom: -40px;
  }
}

.alice-carousel__stage-item.__target {
  opacity: 1 !important;
}

.alice-carousel__stage-item {
  margin-top: 30px;
  margin-bottom: 30px;
  opacity: 0.4 !important;

  @media (min-width: 0px) {
    padding-right: 0px !important;
  }

  @media (min-width: 600px) {
    padding-right: 15px !important;
  }
}

.alice-carousel__dots-item {
  width: 0.7rem !important;
  height: 0.7rem !important;
  margin-right: 12px !important;
  background-color: #a593ff !important;
}

.alice-carousel__dots-item.__active {
  width: 1rem !important;
  height: 1rem !important;
  background-color: #6d51f6 !important;
}

.event_slider {
  padding-left: 0;
  .alice-carousel__prev-btn {
    width: calc(100% - 4rem);
    float: left;
    margin-right: 0;
  }
  .alice-carousel__next-btn {
    width: 4rem;
    margin-right: 0 !important;
    float: right;
    padding-right: 0.5rem;
  }
  .alice-carousel__stage-item {
    opacity: 1 !important;
  }
}
