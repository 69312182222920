@import "./variables";

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 4px 1px;
  .rbc-event-content {
    @extend .rbc-ellipsis;
    margin-bottom: 4px;
  }
}

.rbc-selected-cell {
  background-color: $date-selection-bg-color !important;
  border-right: transparent;
}

.rbc-show-more {
  @extend .rbc-ellipsis;
  z-index: $event-zindex;
  font-weight: 600;
  font-size: 0.75rem;
  margin: 0.25em 0.5em;
  color: $primaryLight500;
}

.rbc-month-view {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  min-height: 100%;
}

.rbc-month-header {
  display: flex;
  border: none;
  flex-direction: row;
  color: $primaryDark500;
  font-size: 0.875rem;
  font-weight: 600;
  border-bottom: 1px solid $cell-border;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  overflow: unset;
  & + & {
    border-top: 1px solid $cell-border;
  }
  .rbc-today {
    background: $primaryGradient
  }
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;

  button {
      border: none;
      color: $primaryDark500;
      font-size: 0.75rem;
      font-weight: 600;
  }

  // &.rbc-now {
  //   background: linear-gradient(
  //     240.98deg,
  //     #e6e8ff 0%,
  //     #ffeae1 68.46%,
  //     #fffbea 99.58%
  //   );
  //}

  > a {
    &,
    &:active,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-bg {
  @extend .rbc-abs-full;
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: unset;
}

.rbc-day-bg {
  flex: 1 0 0%;
  & + & {
    border-left: 1px solid $cell-border;
  }
  .rbc-rtl & + & {
    border-right: 1px solid $cell-border;
  }
  &.rbc-now {
    font-weight: bold;
    height: 100%;
  }
}
