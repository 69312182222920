@import "./variables";

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;

  table.rbc-agenda-table {
    width: 100%;
    border-bottom: 1px solid $cell-border;
    border-spacing: 0;
    border-collapse: collapse;

    tbody > tr > td {
      vertical-align: center;
    }

    .rbc-agenda-time-cell {
      padding-left: 1.6rem;
      padding-right: 1rem;
      border-left: 1px solid $cell-border;
      text-transform: uppercase;
      font-size: 0.85rem;
      text-align: right;
      color: $primaryDark600;
    }

    tbody > tr > td + td {
      border-left: 1px solid $cell-border;
    }

    tbody > tr + tr {
      border-top: 1px solid $cell-border;
    }

    thead > tr > th {
      color: $primaryDark500;
      font-size: 0.875rem;
      font-weight: 600;
      border-bottom: 1px solid $cell-border;
    }
  }
}

.rbc-agenda-time-cell {
  .rbc-continues-after:after {
    content: " »";
  }
  .rbc-continues-prior:before {
    content: "« ";
  }
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
  padding-left: 1.6rem;
  padding-right: 1rem;
  border-left: 1px solid $cell-border;
  font-size: 0.85rem;
  text-align: right;
  color: $primaryDark600;
}

.rbc-agenda-date-cell {
  color: $primaryDark500;
}

.rbc-agenda-event-cell {
  width: 100%;
  color: $primaryDark600;
  font-size: 0.85rem;
}

.rbc-agenda-empty {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primaryDark500;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  line-height: 1.3125rem;
  font-size: 1.375rem;
}
