@font-face {
    font-family: "Gellix-Black";
    font-style: normal;
    font-weight: 400;
    src: local("Gellix-Black"), url("./assets/fonts/Gellix/Gellix-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Gellix-BlackItalic";
    font-style: italic;
    font-weight: 400;
    src: local("Gellix-BlackItalic"), url("./assets/fonts/Gellix/Gellix-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gellix-Bold";
    font-style: bold;
    font-weight: 700;
    src: local("Gellix-Bold"), url("./assets/fonts/Gellix/Gellix-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Gellix-BoldItalic";
    font-style: italic;
    font-weight: 700;
    src: local("Gellix-BoldItalic"), url("./assets/fonts/Gellix/Gellix-BoldItalic.ttf") format("truetype");
}


@font-face {
    font-family: "Gellix-ExtraBold";
    font-style: bold;
    font-weight: 800;
    src: local("Gellix-ExtraBold"), url("./assets/fonts/Gellix/Gellix-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Gellix-ExtraBoldItalic";
    font-style: italic;
    font-weight: 800;
    src: local("Gellix-ExtraBoldItalic"), url("./assets/fonts/Gellix/Gellix-ExtraBoldItalic.ttf") format("truetype");
}


@font-face {
    font-family: "Gellix-Light";
    font-style: normal;
    font-weight: 300;
    src: local("Gellix-Light"), url("./assets/fonts/Gellix/Gellix-Light.ttf") format("truetype");
}


@font-face {
    font-family: "Gellix-LightItalic";
    font-style: italic;
    font-weight: 300;
    src: local("Gellix-LightItalic"), url("./assets/fonts/Gellix/Gellix-LightItalic.ttf") format("truetype");
}


@font-face {
    font-family: "Gellix-Medium";
    font-style: normal;
    font-weight: 500;
    src: local("Gellix-Medium"), url("./assets/fonts/Gellix/Gellix-Medium.ttf") format("truetype");
}



@font-face {
    font-family: "Gellix-MediumItalic";
    font-style: italic;
    font-weight: 500;
    src: local("Gellix-MediumItalic"), url("./assets/fonts/Gellix/Gellix-MediumItalic.ttf") format("truetype");
}


@font-face {
    font-family: "Gellix-Regular";
    font-style: normal;
    font-weight: 400;
    src: local("Gellix-Regular"), url("./assets/fonts/Gellix/Gellix-Regular.ttf") format("truetype");
}



@font-face {
    font-family: "Gellix-RegularItalic";
    font-style: italic;
    font-weight: 400;
    src: local("Gellix-RegularItalic"), url("./assets/fonts/Gellix/Gellix-RegularItalic.ttf") format("truetype");
}


@font-face {
    font-family: "Gellix-SemiBold";
    font-style: normal;
    font-weight: 600;
    src: local("Gellix-SemiBold"), url("./assets/fonts/Gellix/Gellix-SemiBold.ttf") format("truetype");
}


@font-face {
    font-family: "Gellix-SemiBoldItalic";
    font-style: italic;
    font-weight: 600;
    src: local("Gellix-SemiBoldItalic"), url("./assets/fonts/Gellix/Gellix-SemiBoldItalic.ttf") format("truetype");
}


@font-face {
    font-family: "Gellix-Thin";
    font-style: normal;
    font-weight: 100;
    src: local("Gellix-Thin"), url("./assets/fonts/Gellix/Gellix-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Gellix-ThinItalic";
    font-style: italic;
    font-weight: 100;
    src: local("Gellix-ThinItalic"), url("./assets/fonts/Gellix/Gellix-ThinItalic.ttf") format("truetype");
}

@font-face {
    font-family: "CooperBT";
    src: local("CooperBT"), url("./assets/fonts/Cooper BT/Cooper.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}