@import './variables';

//REMOVE AFTER CUSTOM COMPONENT IS BUILD 

.rbc-event {
  cursor: pointer;
  margin: 0 0.75rem;
  text-align: left;
}

.rbc-event-label {
  @extend .rbc-ellipsis;
  font-size: 0.75rem;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51,51,51,.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
