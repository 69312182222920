@import "./variables";
@import "./time-column";

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background: $primaryGradient;
  color: $time-selection-color;
  font-size: 75%;
  width: 100%;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
  
  .rbc-time-gutter {
    white-space: nowrap;
    text-align: right;
  }
  
  .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 0px;
    position: relative;
  }
  
  .rbc-allday-events {
    position: relative;
    z-index: 4;
  }

  .rbc-row {
    box-sizing: border-box;
    min-height: 30px;
  }
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto; // should not shrink below height
  flex-direction: row;

  .rbc-rtl &.rbc-overflowing {
    border-right-width: 0;
    // border-left: 1px solid $cell-border;
  }

  > .rbc-row:first-child {
    border-bottom: 1px solid $secondaryBlue100;
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid $secondaryBlue100;
  }

  .rbc-header {
    .rbc-button-link {
      border: none;
      color: $primaryDark500;
      font-size: 0.75rem;
      font-weight: 600;
    }
  }


}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  color: black;

  .rbc-rtl & {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid $cell-border;
    flex-shrink: 0;
  }
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  position: relative;
  
  > .rbc-time-gutter {
    flex: none;
    padding-left: 2rem;
    padding-right: 0.75rem;
    border-bottom: none;
  }
  
  > * + * > * {
    border-left: 1px solid $cell-border;
  }
  
  .rbc-rtl & > * + * > * {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }
  
  > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
  }
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 4px;

  background: $primaryGradient;
  pointer-events: none;
}
